import { Tokens, Ds } from "@accurx/design";
import { PageHead } from "../components/shared/PageHead";
import styled from "styled-components";
import { StyledPaddingBreakPoints } from "../components/shared/CommonStyles.styles";
import { PageWrapper } from "../components/shared/PageWrapper";

const StyledCentredTextWrapper = styled.div`
    text-align: center;
    padding: ${Tokens.SIZES[2]};
    ${StyledPaddingBreakPoints};
`;

export const MaintenanceSection = ({
    workspaceName,
}: {
    workspaceName?: string;
}): JSX.Element => {
    return (
        <PageWrapper workspaceName={workspaceName}>
            <PageHead text="Scheduled Maintenance" />
            <Ds.Flex
                pt="3"
                flexDirection="column"
                justifyContent="center"
                alignItems="center"
            >
                <Ds.Halo color="greyscale">
                    <Ds.Icon name="Cog" appearance="outline" />
                </Ds.Halo>
                <StyledCentredTextWrapper>
                    <Ds.Text as="h1" weight="bold" size="xxlarge">
                        Scheduled maintenance
                    </Ds.Text>
                    <Ds.Text>
                        This service is temporarily unavailable due to
                        maintenance. We appreciate your patience and assure you
                        that normal service will resume as soon as possible
                    </Ds.Text>
                </StyledCentredTextWrapper>
            </Ds.Flex>
        </PageWrapper>
    );
};
