import { Tokens, Text } from "@accurx/design";
import { ReactNode } from "react";
import styled from "styled-components";
import PageHeader from "../PageHeader";

interface PageWrapperProps {
    children: ReactNode;
    workspaceName?: string;
    isConvoPage?: boolean;
}

const StyledWrapper = styled.div`
    min-height: 100vh;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    background: ${Tokens.COLOURS.greyscale.white};
`;

const StyledText = styled(Text)`
    max-width: 550px;
    font-size: ${Tokens.SIZES[2]};
`;

export const PageWrapper = ({
    children,
    workspaceName,
    isConvoPage,
}: PageWrapperProps) => {
    return (
        <StyledWrapper>
            {!isConvoPage && (
                <PageHeader
                    headerContent={
                        <StyledText variant="subtitle" colour="white">
                            {workspaceName}
                        </StyledText>
                    }
                />
            )}
            {children}
        </StyledWrapper>
    );
};
